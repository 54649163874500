<template>
  <CCol sm="12" class="deckTable">
    <dataTableExtended
      class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="formatedItems"
      :items-per-page="5"
      pagination
      :noItemsView="tableText.noItemsViewText"
      hover
      sorter
    >
  </dataTableExtended>
  </CCol>
</template>
<script>
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import GeneralMixin from '@/_mixins/general';

function data() {
  return {
  };
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.GeneralCargoDeckJson.map((item) => {
    return {
      ...item,
      Nro: Number(item.IdX),
      PortActivityName: item['PortActivityName'+_lang] ?? '',
      PackagingName: item['PackagingName'+_lang] ?? '',
      ComputedQuantityPlanning: item.QuantityPlanning > 0 ? formatMilDecimal(item.QuantityPlanning) : 0,
      ComputedQuantityVisit: item.QuantityVisit > 0 ? formatMilDecimal(item.QuantityVisit) : 0,
      VesselUbicationName: item.VesselHoldId ? `${item['VesselUbicationName'+_lang] ?? ''} (${item?.VesselHoldName ?? ''})` : (item['VesselUbicationName'+_lang] ?? ''),
    };
  });
}

function fields(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%;',
      _classes: 'text-center',
    },          
    { key: 'PortActivityName', label: this.$t('label.portActivity'), _style: 'width:19%;', _classes: 'text-center' },
    { key: 'PackagingName', label: this.$t('label.packaging'), _style: 'width:19%;', _classes: 'text-center' },
    { key: 'ComputedQuantityPlanning', label: this.$t('label.plannedQuantity'), _style: 'width:19%;', _classes: 'text-center' },
    { key: 'ComputedQuantityVisit', label: this.$t('label.quantityExecuted'), _style: 'width:19%;', _classes: 'text-center' },
    { key: 'VesselUbicationName', label: this.$t('label.location'), _style: 'width:19%;', _classes: 'text-center' },
  ];
}

export default {
  name: 'deck-table',
  data,
  mixins: [GeneralMixin],
  props: {
    GeneralCargoDeckJson: {
      type: Array,
      default: () => [],
    }
  },
  methods: {},  
  computed: {
    formatedItems,
    fields,
  },
};
</script>
<style scoped>
.deckTable{
  overflow-y: scroll;
  height: 200px;
}
</style>